<template>
     <div>
         Lista de planes
     </div>
</template>

<script>
  import {mapActions} from 'vuex' 

  export default{
    name: 'PlansList',
    components: {},
    beforeMount() { 
    },
    methods: { 
    },
  }
</script>

<style scoped>  
</style>
